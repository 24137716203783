import React from "react";
import LoginMenu from "../components/LoginRegister/LoginMenu";
const LoginPage = () => {
  return (
    <>
      <LoginMenu />
    </>
  );
};

export default LoginPage;
