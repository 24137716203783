import React from "react";
import LoginMenu from "../components/LoginRegister/LoginMenu";
import MyMenu from "../components/My/MyMenu";

const MyPage = () => {
  //const isLogin = !!localStorage.getItem("token");
  return <MyMenu />;
};

export default MyPage;
