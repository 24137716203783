import React, { useState } from "react";
import CreateCharm from "../components/CreateCharm/CreateCharm";

const CreateCharmPage = () => {
  return (
    <>
      <CreateCharm />
    </>
  );
};

export default CreateCharmPage;
